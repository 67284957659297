//cs捷克
export const cs = {
    //Navigační lišta
    'n1': 'Domů',
    'n2': 'Objednat',
    'n3': 'Transakce',
    'n4': 'Tým',
    'n5': 'moje',
    //třída výzvy
    't1': 'Došlo k chybě',
    't2': 'Kopírování bylo úspěšné',
    't3': 'Vyplňte prosím úplně informace',
    't4': 'Načítání...',
    't5': 'Přihlášení úspěšné',
    't6': 'Pro odeslání musí být skóre vyšší než 3',
    't7': 'Odeslání úspěšné',
    't8': 'Nedostatečný zůstatek, nelze přijímat',
    't9': 'Odeslání selhalo',
    't10': 'Nejprve svažte adresu a budete přesměrováni na stránku osobního centra',
    't11': 'Adresa byla svázána, kontaktujte prosím zákaznický servis, abyste ji upravili',
    't12': 'Úprava úspěšná',
    't13': 'Modifikace se nezdařila',
    't14': 'Pro změnu hesla kontaktujte zákaznický servis',
    't15': 'Chyba formátu hesla',
    't16': 'Výběr úspěšný',
    't17': 'Maximální výběry',
    't18': 'Minimální výběr',
    't19': 'Dvě hesla jsou nekonzistentní',
    't20': 'Výzva',
    't21': 'Dočasně nelze přeskočit',
    't22': 'Chyba formátu hesla',
    't23': 'Registrace úspěšná',
    't24': 'Zadejte kód oblasti',
    't25': 'Už ne',
    //Přihlásit se
    'l1': 'Jazyk',
    'l2': 'Zadejte prosím číslo svého účtu',
    'l3': 'Zadejte heslo',
    'l4': 'pamatovat',
    'l5': 'Přihlášení',
    'l6': 'Přejít na registraci',
    'l7': 'Žádný účet',
    'l8': 'Číslo mobilního telefonu',
    'l9': 'přezdívka',
    'l10': 'heslo',
    'l11': 'Potvrdit heslo',
    'l12': 'kód pozvánky',
    'l13': 'Registrovat',
    'l14': 'Už máte účet',
    'l15': 'Přejít na přihlášení',
    'l16': 'Zadejte 6-16místné heslo',

    //Pomoc
    'bz1': 'Nápověda',
    'bz2': 'FAQ',
    'bz3': 'Pravidla obchodování',
    'bz4': 'Jak vybrat peníze',
    'bz5': 'Jak dobíjet',
    'bz6': '1.Co je TiktokShop?',
    'bz7': 'TiktokShop je marketingová a propagační společnost, která pomáhá obchodníkům s internetovými obchody TiktokShop po celém světě získat více objednávek a zvýšit údaje o prohlížení online obchodu TiktokShop. Jsme odhodláni zavést tripartitní ziskový marketingový a propagační model mezi TiktokShop, obchodníky a spotřebiteli. V kombinaci s nejnovější technologií P2P blockchain jsou spotřebitelé a obchodníci rychle propojeni prostřednictvím USDT (TRC20, ERC20). Registrovaní uživatelé mohou získat provize z objednávek, zatímco obchodníci mohou zvýšit údaje o prodejích svého obchodu. Je to nejnovější model zisku v internetovém blockchainovém modelu! ',
    'bz8': '2.Jak TiktokShop funguje?',
    'bz9': 'Denní zpětná vazba od TiktokShop ukazuje, že produkty, které potřebují vylepšení, se prodávají v TiktokShop. Uživatelé stačí jednoduše kliknout na objednávku a systém automaticky vygeneruje předplatné objednávky. Uživatelé zaplatí částku objednávky prostřednictvím blockchainu USDT a dostávají denní provize. ',
    'bz10': '3. Proč se ceny stejné měny liší ve dvou transakcích? ',
    'bz11': 'Cenové rozdíly jsou způsobeny všemi faktory, které nevedou k volnému oběhu měn, včetně rychlosti převodů měn, podmínek sítě, omezení přístupu k měně, rozpoznávání měny lidmi v různých regionech a dokonce i obchodními páry poskytované typy burz, transakcí atd. Proto může mít stejná měna cenové rozdíly v různých transakcích. ',
    'bz12': '4. Investiční zisk? ',
    'bz13': 'Čím vyšší cenu produktu získáte, tím vyšší zisk z objednávky získáte. Zároveň TiktokShop náhodně rozděluje velké provizní objednávky. ',
    'bz14': 'Systém každý den automaticky generuje a distribuuje uživatelům 60 objednávek produktů. Uživatelé za každou objednávku zaplatí prostřednictvím USDT a obdrží provizi 0.6%. Velké provizní zakázky jsou přidělovány náhodně. ',
    'bz15': 'USDT lze vybrat po 60 objednávkách. (Pokud nebude dokončeno 60 objednávek, systém se automaticky zastaví až do zbývajících objednávek daného dne)',
    'bz16': 'Každý účet může být svázán pouze s jedním účtem USDT. Pokud dojde k chybě, kontaktujte zákaznický servis',
    'bz17': 'Po dokončení doručení denních objednávek můžete peníze normálně vybírat. Přijdou do 24 hodin po zahájení výběru a neexistuje žádný limit pro výběr',
    'bz18': 'Prosím svažte svou adresu pro výběr (podporuje TRC-20) USDT adresu před dobitím. Můžete kliknout na Recharge na APP nebo webové stránce a vybrat blockchain (TRC-20), který používáte k dobíjení. ',
    'bz19': 'Dobíjení nebo převod prostředků mezi přidruženými účty není povoleno. Jedná se o zlomyslné chování při získávání příkazů a vážně ovlivňuje provozní pravidla platformy, pokud je takové chování zjištěno, systém bude účet pokutovat a trestá poprvé 15 % prostředků na účtu, opakované zlomyslné chování při získávání příkazů vás přímo vyřadí z práce',
    'bz20': 'Přidružené účty musí dokončit úkoly objednávky na účtu každého dne, než budou moci pokračovat v aktualizaci úkolů objednávky na další den. Pokud mezi přidruženými účty existuje nedokončená úloha, aktualizace úkolů objednávky se zastaví dokud nebudou dokončeny všechny úkoly objednávky',

    //text modulu
    //Obvyklá slova
    'ty1': 'OK',
    'ty2': 'Zrušit',
    'ty3': 'Odeslat',
    'ty4': 'adresa',

    //přední strana
    's1': 'Kumulativní příjem',
    's2': 'Služba',
    's3': 'O nás',
    's4': 'Nápověda',
    's5': 'Partner',
    's6': 'Moje služba',
    //Objednat
    'd1': 'Nedokončeno',
    'd2': 'Complete',
    'd3': 'Zatím žádná data',
    'd4': 'liché číslo',
    'd5': 'Doba obchodování',
    'd6': 'částka',
    'd7': 'Aktuální počet úkolů',
    'd8': 'Očekávaný výnos',
    'd9': 'Potřebuji víc',
    'd10': 'Hodnocení',
    //obchod
    'j1': 'Zůstatek na účtu',
    'j2': 'Obchodní úvod',
    'j3': 'TiktokShop využívá zpětnou vazbu TiktokShop každý den, aby ukázal, že produkty prodávané společností TiktokShop potřebují vylepšení. Uživatelům stačí kliknout na objednávku a systém automaticky vygeneruje podpis objednávky. Uživatelé zaplatí částku objednávky prostřednictvím blockchainu USDT a mohou pokaždé získat více než 0,6% provizi a systém náhodně rozděluje velké objednávky odměn. ',
    'j4': 'Zahájit párování',
    'j5': 'Dnešní výdělky',
    'j6': 'Číslo dokončeno',
    'j7': 'Celkový počet úkolů',
    //tým
    'td1': 'Zůstatek',
    'td2': 'provize',
    'td3': 'Přijmout',
    'td4': 'Celkový počet lidí',
    'td5': 'uživatel',
    'td6': 'příspěvek',
    'td7': 'Množství',
    'td8': 'stav',
    //Osobní centrum
    'g1': 'Stáhnout',
    'g2': 'Recharge',
    'g3': 'Pozvat přátele',
    'g4': 'Pozvat nyní',
    'g5': 'Za každý zisk, který váš přítel po registraci dosáhne, získáte odpovídající podíl provize',
    'g6': 'Osobní údaje',
    'g7': 'Podrobnosti fondu',
    'g8': 'Recharge record',
    'g9': 'Záznam o výběru',
    'g10': 'Jazyk',
    'g11': 'Odhlásit se',
    'g12': 'Opravdu se chcete odhlásit?',
    //Uživatelské informace
    'yh1': 'Informace o uživateli',
    'yh2': 'Upravit',
    'yh3': 'nastavení',
    'yh4': 'telefon',
    'yh5': 'Opravdu se chcete odhlásit?',
    'yh6': 'Původní heslo',
    'yh7': 'Nové heslo',
    //Podrobnosti
    'mx1': 'Sbírka provizí',
    'mx2': 'zisk',
    'mx3': 'Výběr zamítnut',
    'mx4': 'čas',
    'mx5': 'Dobití úspěšné',
    'mx6': 'Odmítnuto',
    'mx7': 'čeká se',
    'mx8': 'Adresa pro výběr',
    'mx9': 'Dostupný zůstatek',
    'mx10': 'Skutečný příjezd',
    'mx11': 'Zadejte částku výběru',
    //doplňte
    'cz1': 'Adresa pro vklad podporuje pouze ERC20 a minimální částka vkladu je 20 USDT',
    'cz2': 'Kopírovat',
    'cz3': 'Výběr měny Fiat',
    'cz4': 'platba',
    'cz5': 'Adresa pro vklad podporuje pouze TRC20-USDT a minimální částka vkladu je 20 USDT',
    'cz6': 'Adresa pro vklad podporuje pouze ERC20 a minimální částka vkladu je 20 USDT',
    'cz1': 'Adresa pro vklad podporuje pouze ERC20 a minimální částka vkladu je 20 USDT',
    'cz1': 'Adresa pro vklad podporuje pouze ERC20 a minimální částka vkladu je 20 USDT',
    //plocha
    'qy1': 'kód oblasti',
    'yq1': 'Pozvat přátele',
    'yq2': 'kód pozvánky',
    'yq3': 'Pozvěte přátele a užívejte si velké výhody',
    'yq4': 'Jdi a pozvi',
}