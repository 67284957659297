import { React, useState, useEffect, useRef } from 'react'
import { Toast } from 'antd-mobile'
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LeftOutline } from 'antd-mobile-icons'
import data from '../utils/phone'
import './area.css'
import axios from 'axios'
import { basciUrl } from "../request/defaultURL";

export default function Area() {
  const { t, i18n } = useTranslation();
  const [datas, setDatas] = useState([])
  const [value, setValue] = useState('')
  const location = useLocation();
  let navigate = useNavigate();
  const [type, setType] = useState('')
  useEffect(() => {
    setType(location.state)
    getList()
  }, [])
  const getList = () => {
    let loadId =  Toast.show({
      icon: 'loading',
      content: t('t4'),
    })
    axios({
      url: basciUrl + "/api/getCountry",
      params: {}
    }).then(res => {
      if (res.data.code == 1) {
        setDatas(res.data.data)
      }
      loadId.close()
    }).catch(err => {loadId.close()})
  }
  const itemClick = (val) => {
    if (type == 1) {
      navigate('/Login', { state: val })
    } else {
      navigate('/Register', { state: val })
    }
  }
  const confirmVal = () => {
    if (value == '') {
      Toast.show({
        icon: 'fail',
        content: t('t24'),
      })
    }
    if (type == 1) {
      navigate('/Login', { state: value })
    } else {
      navigate('/Register', { state: value })
    }
  }
  return (
    <div>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate(-1) }}>
          <LeftOutline />
        </div>
        <p>{t('qy1')}</p>
      </div>
      <div className='area_box'>
        <div className='input_area'>
          <p>+</p>
          <input type="number" value={value || ""} onChange={(e) => { setValue(e.target.value) }} />
          <p onClick={confirmVal}> {t('ty1')} </p>
        </div>
        <div className='area_list'>
          {
            datas.map((item, index) => {
              return (
                <div key={index} className="area_item" onClick={() => { itemClick(item.code) }}>
                  <img src={item.pic} alt="" />
                  <p>+{item.code}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div >
  )
}
