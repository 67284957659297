//日语
export const jp = {
    //ナビゲーションバー
    'n1': 'ホーム',
    'n2': '注文',
    'n3': 'トランザクション',
    'n4': 'チーム',
    'n5': '私の',
    //プロンプトクラス
    't1': 'エラーが発生しました',
    't2': 'コピー成功',
    't3': '情報を完全に入力してください',
    't4': '読み込み中...',
    't5': 'ログイン成功',
    't6': '送信するにはスコアが 3 より大きくなければなりません',
    't7': '送信成功',
    't8': '残高不足のため受信できません',
    't9': '送信に失敗しました',
    't10': '最初にアドレスをバインドしてください。個人センター ページにリダイレクトされます',
    't11': 'アドレスはバインドされています。変更するにはカスタマーサービスに連絡してください',
    't12': '変更は成功しました',
    't13': '変更に失敗しました',
    't14': 'パスワードを変更するにはカスタマーサービスにご連絡ください',
    't15': 'パスワード形式エラー',
    't16': '出金成功',
    't17': '最大出金額',
    't18': '最低出金額',
    't19': '2 つのパスワードが一致しません',
    't20': 'プロンプト',
    't21': '一時的にジャンプできません',
    't22': 'パスワード形式エラー',
    't23': '登録に成功しました',
    't24': '市外局番を入力してください',
    't25': 'もうだめです',
    //サインアップ
    'l1': '言語',
    'l2': 'アカウント番号を入力してください',
    'l3': 'パスワードを入力してください',
    'l4': '覚えておいてください',
    'l5': 'ログイン',
    'l6': '登録に移動',
    'l7': 'アカウントがありません',
    'l8': '携帯電話番号',
    'l9': 'ニックネーム',
    'l10': 'パスワード',
    'l11': 'パスワードの確認',
    'l12': '招待コード',
    'l13': '登録',
    'l14': 'すでにアカウントをお持ちです',
    'l15': 'ログインに移動',
    'l16': '6 ～ 16 桁のパスワードを入力してください',

    //ヘルプ
    'bz1': 'ヘルプ',
    'bz2': 'よくある質問',
    'bz3': '取引ルール',
    'bz4': 'お金を引き出す方法',
    'bz5': '充電方法',
    'bz6': '1.TiktokShop とは何ですか？',
    'bz7': 'TiktokShop は、世界中の TiktokShop オンライン ストア販売者がより多くの注文を獲得し、TiktokShop オンライン ストアの閲覧データを増やすのを支援するマーケティングおよびプロモーション会社です。 当社は、TiktokShop、加盟店、消費者の間で三者関係による収益性の高いマーケティングおよびプロモーション モデルを確立することに取り組んでいます。 最新の P2P ブロックチェーン技術と組み合わせることで、消費者と販売者は USDT (TRC20、ERC20) を通じて迅速に接続されます。 登録ユーザーは注文に対して手数料を得ることができ、販売者は店舗の販売データを増やすことができます。 インターネットブロックチェーンモデルの最新収益モデルです！ ',
    'bz8': '2.TiktokShopはどのように機能しますか?',
    'bz9': 'TiktokShop からの毎日のフィードバックは、改善が必要な製品が TiktokShop で販売されていることを示しています。ユーザーは簡単に注文をクリックするだけで、システムが自動的に注文の購読を生成します。 ユーザーはブロックチェーン USDT 経由で注文金額を支払い、毎日コミッションを受け取ります。 ',
    'bz10': '3. 2 つの取引で同じ通貨の価格が異なるのはなぜですか? ',
    'bz11': '価格差は、通貨の送金速度、ネットワークの状態、通貨へのアクセス制限、異なる地域の人々による通貨の認識、さらには取引ペアなど、通貨の自由な流通を妨げるあらゆる要因によって引き起こされます。取引所の種類、トランザクションなどによって提供されます。 したがって、同じ通貨でも異なる取引では価格に差異が生じる可能性があります。 ',
    'bz12': '4. 投資利益? ',
    'bz13': '商品価格が高くなるほど、得られる注文利益も高くなります。 同時に、TiktokShop は大規模なコミッション注文をランダムに分配します。 ',
    'bz14': '毎日、システムは 60 個の製品注文を自動的に生成し、ユーザーに配布します。ユーザーは USDT を通じて各注文の支払いを完了し、0.6% の手数料を受け取ります。 高額なコミッション注文はランダムに割り当てられます。 ',
    'bz15': 'USDTは60注文後に出金可能です。 （60件の注文が完了しない場合、その日の残りの注文が完了するまでシステムは自動的に停止します）',
    'bz16': '各アカウントは 1 つの USDT アカウントにのみバインドできます。エラーが発生した場合は、カスタマー サービスにお問い合わせください',
    'bz17': '毎日の注文の配達が完了したら、通常通り出金できます。出金開始から24時間以内に到着し、出金制限はありません',
    'bz18': 'リチャージする前に、出金アドレス (TRC-20 をサポート) USDT アドレスをバインドしてください。APP または Web ページで [リチャージ] をクリックし、リチャージに使用するブロックチェーン (TRC-20) を選択できます。 ',
    'bz19': "関連するアカウント間での資金のリチャージや送金は許可されていません。これは悪意のある注文獲得行為であり、プラットフォームの運用ルールに重大な影響を与えます。そのような行為が見つかった場合、システムはそのアカウントに罰金を科します。最初のペナルティは、アカウント資金の 15% が課せられます。悪質な注文獲得行為を繰り返すと、直接、仕事から剥奪されます",
     'bz20': "関連付けられたアカウントは、翌日の注文タスクの更新を続ける前に、毎日のアカウントで注文タスクを完了する必要があります。関連付けられたアカウント間に未完了のタスクがある場合、注文タスクの更新は停止します。すべての注文タスクが完了するまで。",
    //モジュールテキスト
    //よく使う単語
    'ty1': 'OK',
    'ty2': 'キャンセル',
    'ty3': '送信',
    'ty4': 'アドレス',
    //表紙
    's1': '累計収入',
    's2': 'サービス',
    's3': '私たちについて',
    's4': 'ヘルプ',
    's5': 'パートナー',
    's6': '私のサービス',
    //注文
    'd1': '元に戻した',
    'd2': '完了しました',
    'd3': 'まだデータがありません',
    'd4': '奇数',
    'd5': '取引時間',
    'd6': '金額',
    'd7': '現在のタスク数',
    'd8': '期待されるリターン',
    'd9': 'もっと必要です',
    'd10': '評価',
    //貿易
    'j1': '口座残高',
    'j2': '取引紹介',
    'j3': 'TiktokShop は、TiktokShop のフィードバックを毎日使用して、TiktokShop が販売する改善が必要な製品を示します。 ユーザーは注文をクリックするだけで、システムが注文署名を自動的に生成します。 ユーザーはUSDTブロックチェーンを通じて注文金額を支払い、毎回0.6%以上の手数料を受け取ることができ、システムは大量の報酬注文をランダムに分配します。 ',
    'j4': 'マッチングを開始',
    'j5': '今日の収益',
    'j6': '番号が完成しました',
    'j7': 'タスクの総数',
    //チーム
    'td1': 'バランス',
    'td2': '手数料',
    'td3': '受信',
    'td4': '総人数',
    'td5': 'ユーザー',
    'td6': '貢献',
    'td7': '数量',
    'td8': 'ステータス',
    //パーソナルセンター
    'g1': '資金を引き出す',
    'g2': 'リチャージ',
    'g3': '友達を招待する',
    'g4': '今すぐ招待',
    'g5': '登録後に友人が得た利益ごとに、あなたは対応する割合のコミッションを受け取ります',
    'g6': '個人情報',
    'g7': 'ファンドの詳細',
    'g8': 'リチャージ記録',
    'g9': '出金記録',
    'g10': '言語',
    'g11': 'ログアウト',
    'g12': 'ログアウトしてもよろしいですか?',
    //ユーザー情報
    'yh1': 'ユーザー情報',
    'yh2': '変更',
    'yh3': '設定',
    'yh4': '電話',
    'yh5': 'ログアウトしてもよろしいですか?',
    'yh6': '元のパスワード',
    'yh7': '新しいパスワード',
    //詳細
    'mx1': '手数料徴収',
    'mx2': '利益',
    'mx3': '出金が拒否されました',
    'MX4': '時間',
    'mx5': '再充電成功',
    'mx6': '拒否されました',
    'mx7': '保留中',
    'mx8': '出金アドレス',
    'mx9': '利用可能な残高',
    'mx10': '実際の到着',
    'mx11': '出金金額を入力してください',
    //補充する
    'cz1': '入金アドレスはERC20のみをサポートしており、最低入金額は20USDTです',
    'cz2': 'コピー',
    'cz3': '法定通貨の選択',
    'cz4': '支払い',
    'cz5': '入金アドレスは TRC20-USDT のみをサポートしており、最低入金額は 20USDT です',
    'cz6': '入金アドレスはERC20のみをサポートしており、最低入金額は20USDTです',
    'cz1': '入金アドレスはERC20のみをサポートしており、最低入金額は20USDTです',
    'cz1': '入金アドレスはERC20のみをサポートしており、最低入金額は20USDTです',
    //エリア
    'qy1': '市外局番',
    'yq1': '友達を招待',
    'yq2': '招待コード',
    'yq3': '友達を招待して大きな特典をお楽しみください',
    'yq4': '行って招待してください',
}
